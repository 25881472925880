import React, { useContext } from 'react'
import navigate from '../utils/navigate'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import { Context as LeaveContext } from '../components/LeaveContext'

export default function New_Leave_Request_Covid_Result_Yes() {
  const { clearCovidInfo } = useContext(LeaveContext)
  const onSubmit = (event) => {
    event.preventDefault()
    clearCovidInfo()
    navigate('/Overview/');
  }

  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Covid Leave Request Result" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            <p>Thank you for submitting your COVID Information</p>
          </div>
          <form className="LeaveFormEntry" onSubmit={onSubmit}>
            <h3>Results:</h3>
            <div className="formDiv">
              <label>You are not required to file a leave of absence at this time and can return to work. The CDC
                advises that fully vaccinated people do not need to quarantine after contact unless they are
                experiencing symptoms. The CDC recommends you are tested 5-7 days after exposure. If your results are
                positive, or you become symptomatic, you will need to quarantine and file a leave of absence. <br />
                <br />
                Please contact the leave center immediately if you need to quarantine and file a leave of absence.<br />
                <br />
                <a
                  href="https://www.cdc.gov/coronavirus/2019-ncov/your-health/quarantine-isolation.html"
                  target="_blank"
                  without
                  rel="noopener noreferrer"
                >
                  Click HERE
                </a>
                &nbsp;to view additional information from the CDC on when you should
                quarantine or isolate.&nbsp;
              </label>
            </div>
            <br className="clear" />
            <p className="clear">&nbsp;</p>
            <input type="submit" id="setp1submit" value="Return to Homepage" />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
